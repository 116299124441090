<template>
  <div>
    <div>
      <b-row class="d-flex align-items-start justify-content-between py-1">
        <b-col md="8" class="bg-colorWhite pt-1" style="border-radius: 20px">
          <b-row class="d-flex align-items-start justify-content-end py-1">
            <b-col md="8" class="px-3 py-50">
              <h3 class="font-weight-bolder text-colorBlack m-0 p-0">
                Statistics
              </h3>
            </b-col>
            <b-col md="4" class="text-right pr-3 mt-50">
              <b-link :to="{ name: 'StartLecture' }">
                <img src="@/assets/images/icons/lecture/cross.svg" img-fluid />
              </b-link>
            </b-col>
          </b-row>
          <b-row class="d-flex justify-content-center px-2">
            <b-col
              md="6"
              style="border: 2px solid lightgray; border-radius: 20px"
            >
              <div class="px-2 py-3">
                <h1 class="font-weight-bolder text-colorBlack m-0 p-0">677</h1>
                <p class="font-weight-bold text-colorGray m-0 p-0">
                  Total Input Words
                </p>
              </div>
            </b-col>
            <b-col
              md=""
              class="ml-1"
              style="border: 2px solid lightgray; border-radius: 20px"
            >
              <div class="px-2 py-3">
                <h1 class="font-weight-bolder text-colorBlack m-0 p-0">677</h1>
                <p class="font-weight-bold text-colorGray m-0 p-0">
                  Total Output Words
                </p>
              </div>
            </b-col>
          </b-row>
          <b-row class="d-flex justify-content-start px-2 mt-1">
            <b-col
              md="6"
              style="border: 2px solid lightgray; border-radius: 20px"
            >
              <div class="px-2 py-3">
                <h1 class="font-weight-bolder text-colorBlack m-0 p-0">
                  45 Mins
                </h1>
                <p class="font-weight-bold text-colorGray m-0 p-0">
                  Minutes Spent
                </p>
              </div>
            </b-col>
          </b-row>
        </b-col>
        <b-col
          align-self="stretch"
          md=""
          class="bg-colorWhite text-right ml-1"
          style="border-radius: 20px"
        >
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
export default {};
</script>

<style></style>
